<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="楼宇编号">
                <a-input placeholder="请输入楼宇编号" v-model="queryParam.keyId" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="楼宇名称">
                <a-input placeholder="请输入楼宇名称" v-model="queryParam.buildingName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="区域">
                <a-cascader
                  :options="regionList"
                  placeholder="省-市-区(县)"
                  :field-names="{label: 'label', value: 'value', children: 'children' }"
                  :value="defaultRegion"
                  @change="changeRegion"
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="选择小区">
                  <a-select v-model="queryParam.communityId" placeholder="请选择小区">
                    <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                    <a-select-option v-for="item in regionCommunity" :key="item.keyId" :value="item.id">
                      {{ item.communityName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">添加</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="gotoUnit(record.id)">查看单元</a>
          <a-divider type="vertical" />
          <a @click="gotoHouse(record.id)">查看房屋</a>
          <a-divider type="vertical" />
          <a @click="gotoResident(record.id)">查看住户</a>
        </span>
      </s-table>
      <a-modal
        title="添加/编辑楼宇"
        :width="800"
        :destroyOnClose="true"
        v-model="visible"
        @ok="saveBuildingApi"
      >
        <a-form :form="form">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="区域"
            :validateStatus="checkParams.areaCodeStatus"
            :help="checkParams.areaCodeStatusMsg"
          >
            <a-cascader
              :allowClear="false"
              style="width:200px"
              :options="regionList"
              placeholder="省-市-区(县)"
              :field-names="{label: 'label', value: 'value', children: 'children' }"
              @change="changeAddRegion"
              v-decorator="['areaCode', {initialValue: addDefaultRegion, rules: [{required: true}]}]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="小区"
            :validateStatus="checkParams.communityIdStatus"
            :help="checkParams.communityIdStatusMsg"
          >
            <a-select
              style="width:200px"
              placeholder="请选择小区"
              v-model="mdl.communityId"
              @change="changeAddCommunity"
            >
              <a-select-option :key="0" :value="0">请选择小区</a-select-option>
              <a-select-option v-for="item in regionCommunityAdd" :key="item.keyId" :value="item.id">
                {{ item.communityName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="楼宇名称"
            :validateStatus="checkParams.buildingNameStatus"
            :help="checkParams.buildingNameStatusMsg"
          >
            <a-input
              placeholder="请输入楼宇名称"
              v-decorator="['buildingName', {initialValue: mdl.buildingName, rules: [{required: true, validator: verifyBuildingName, validateTrigger: 'change'}]}]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="备注"
          >
            <a-textarea :rows="5" v-model="mdl.desc" placeholder="备注" />
          </a-form-item>
        </a-form>
      </a-modal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import { regionCommunity } from '@/api/community'
import { regionList } from '@/api/region'
import { buildingCondition, buildingList, checkBuilding, saveBuilding } from '@/api/building'
const columns = [
  {
    title: '楼宇编号',
    dataIndex: 'keyId'
  },
  {
    title: '楼宇名称',
    dataIndex: 'buildingName'
  },
  {
    title: '小区名称',
    dataIndex: 'communityName'
  },
  {
    title: '区域',
    dataIndex: 'areaCodeName'
  },
  {
    title: '添加时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '300px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'CommunityList',
  components: {
    STable,
    Ellipsis,
    STree
  },
  data () {
    this.columns = columns
    return {
      description: '',
      visible: false,
      form: this.$form.createForm(this, { name: 'addRoleFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        keyId: '',
        buildingName: '',
        communityId: 0,
        areaCode: 0,
        desc: ''
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return buildingList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      checkParams: {
        buildingNameStatus: 'success',
        buildingNameStatusMsg: null,
        areaCodeStatus: 'success',
        areaCodeStatusMsg: null,
        communityIdStatus: 'success',
        communityIdStatusMsg: null
      },
      dataParams: {
        communityName: ''
      },
      regionList: [],
      defaultRegion: [],
      addDefaultRegion: [],
      regionCommunity: [],
      regionCommunityAdd: []
    }
  },
  filters: {
  },
  created () {
    this.regionListApi()
    if (this.$route.query.communityId !== 0 && this.$route.query.communityId !== undefined && this.$route.query.communityId !== '') {
      this.queryParam.communityId = this.$route.query.communityId
      this.buildingConditionApi(this.queryParam.communityId)
    }
  },
  watch: {
    $route (val) {
      if (val.name === 'buildingInfoManage') {
        this.queryParam = {
          keyId: '',
          buildingName: '',
          communityId: 0,
          areaCode: 0,
          desc: ''
        }
        this.defaultRegion = []
        this.regionListApi()
        if (this.$route.query.communityId !== 0 && this.$route.query.communityId !== undefined && this.$route.query.communityId !== '') {
          this.queryParam.communityId = this.$route.query.communityId
          this.buildingConditionApi(this.queryParam.communityId)
        } else {
          this.regionCommunity = []
        }
        this.$refs.table.refresh(true)
      }
    }
  },
  methods: {
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    buildingConditionApi (communityId) {
      const _this = this
      buildingCondition({ communityId: communityId }).then((res) => {
        if (res.errorCode === 0) {
          _this.regionCommunity = res.result.regionCommunity
          _this.defaultRegion = res.result.defaultRegion
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    verifyBuildingName (rule, value, callback) {
      const _this = this
      _this.mdl.buildingName = value.trim()
      if (_this.mdl.buildingName !== '') {
        _this.checkParams.buildingNameStatus = 'success'
        _this.checkParams.buildingNameStatusMsg = null
      } else {
        _this.checkParams.buildingNameStatus = 'error'
        _this.checkParams.buildingNameStatus = '请输入楼宇名称'
        callback(new Error('请输入楼宇名称'))
      }
    },
    resetSearchForm () {
      this.defaultRegion = []
      this.queryParam = {
        keyId: '',
        buildingName: '',
        communityId: 0,
        areaCode: 0,
        desc: ''
      }
    },
    saveBuildingApi () {
      const _this = this
      _this.dataParams.keyId = _this.mdl.keyId ? _this.mdl.keyId : 0
      _this.dataParams.buildingName = _this.mdl.buildingName
      _this.dataParams.communityId = _this.mdl.communityId
      _this.dataParams.areaCode = _this.mdl.areaCode
      _this.dataParams.desc = _this.mdl.desc
      console.log(_this.dataParams)
      if (_this.dataParams.areaCode === '' || _this.dataParams.areaCode === 0 || _this.dataParams.areaCode === '请选择区域') {
        _this.checkParams.areaCodeStatus = 'error'
        _this.checkParams.areaCodeStatusMsg = '请选择区域'
        return false
      } else {
        _this.checkParams.areaCodeStatus = 'success'
        _this.checkParams.areaCodeStatusMsg = null
      }

      if (_this.dataParams.communityId === '' || _this.dataParams.communityId === 0) {
        _this.checkParams.communityIdStatus = 'error'
        _this.checkParams.communityIdStatusMsg = '请选择小区'
        return false
      } else {
        _this.checkParams.communityIdStatus = 'success'
        _this.checkParams.communityIdStatusMsg = null
      }

      if (_this.dataParams.buildingName === '') {
        _this.checkParams.buildingNameStatus = 'error'
        _this.checkParams.buildingNameStatusMsg = '请输入楼宇名称'
        return false
      } else {
        _this.checkParams.buildingNameStatus = 'success'
        _this.checkParams.buildingNameStatusMsg = null
      }

      checkBuilding(_this.dataParams).then((resc) => {
        console.log('checkBuilding', resc)
        if (resc.errorCode === 0) {
          saveBuilding(_this.dataParams).then((res) => {
            if (res.errorCode === 0) {
              if (_this.dataParams.keyId > 0) {
                _this.$message.success('更新成功')
              } else {
                _this.$message.success('添加成功')
              }
              _this.visible = false
              _this.$refs.table.refresh()
            } else {
              _this.$message.error(res.errorMsg)
            }
          }).catch((err) => {
            console.log(err)
          })
        } else {
          _this.checkParams.buildingNameStatus = 'error'
          _this.checkParams.buildingNameStatusMsg = resc.errorMsg
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleAdd () {
      this.mdl = {
        keyId: 0,
        id: '',
        buildingName: '',
        desc: '',
        communityId: 0,
        areaCode: 0
      }
      this.resetCheck()
      this.addDefaultRegion = []
      this.regionCommunityAdd = []
      this.visible = true
    },
    handleEdit (record) {
      // this.cityListInfoApi(record.provinceId)
      // this.areaListInfoApi(record.cityId)
      this.mdl = Object.assign({}, record)
      this.addDefaultRegion = [record.provinceId, record.cityId, record.areaCode]
      console.log(this.mdl)
      this.regionCommunityApi(record.areaCode, 1)
      this.resetCheck()
      this.visible = true
    },
    resetCheck () {
      this.checkParams.areaCodeStatus = 'success'
      this.checkParams.areaCodeStatusMsg = null
      this.checkParams.buildingNameStatus = 'success'
      this.checkParams.buildingNameStatusMsg = null
      this.checkParams.communityIdStatus = 'success'
      this.checkParams.communityIdStatusMsg = null
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    onChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    changeRegion (e) {
      console.log('changeRegion', e)
      this.defaultRegion = e
      this.queryParam.areaCode = e[2] ? e[2] : 0
      this.queryParam.communityId = 0
      if (this.queryParam.areaCode > 0) {
        this.regionCommunityApi(this.queryParam.areaCode, 0)
      } else {
        this.regionCommunity = []
      }
    },
    changeAddRegion (e) {
      this.addDefaultRegion = e
      this.mdl.provinceId = e[0] ? e[0] : 0
      this.mdl.cityId = e[1] ? e[1] : 0
      this.mdl.areaCode = e[2] ? e[2] : 0

      if (this.mdl.areaCode === '' || this.mdl.areaCode === 0) {
        this.checkParams.areaCodeStatus = 'error'
        this.checkParams.areaCodeStatusMsg = '请选择区域'
        return false
      } else {
        this.checkParams.areaCodeStatus = 'success'
        this.checkParams.areaCodeStatusMsg = null
      }

      this.regionCommunityApi(this.mdl.areaCode, 1)
      this.mdl.communityId = 0
    },
    changeAddCommunity (e) {
      console.log(e)
      this.mdl.communityId = e
      if (this.mdl.communityId === '' || this.mdl.communityId === 0) {
        this.checkParams.communityIdStatus = 'error'
        this.checkParams.communityIdStatusMsg = '请选择小区'
      } else {
        this.checkParams.communityIdStatus = 'success'
        this.checkParams.communityIdStatusMsg = null
      }
    },
    regionCommunityApi (areaCode, type) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionCommunity = res.result.data
          } else {
            _this.regionCommunityAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    gotoUnit (buildingId) {
      this.$router.push({ path: '/home/unit-list?buildingId=' + buildingId })
    },
    gotoHouse (buildingId) {
      this.$router.push({ path: '/home/house-list?buildingId=' + buildingId })
    },
    gotoResident (buildingId) {
      this.$router.push({ path: '/resident/resident-list?buildingId=' + buildingId })
    }
  }
}
</script>
